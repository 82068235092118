<template>
  <section id="faq-hangman" class="faq-hangman">
    <div>
      <h2>{{ c.title }}</h2>
      <img src="../assets/question.png" alt="" />
      <div class="container">
        <FaqItem
            v-for="(question, index) in c.questions"
            :key="index"
            :question="question.question"
            :answer="question.answer" />
      </div>
    </div>
  </section>
</template>

<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
import FaqItem from "@/components/FaqItem";

const { getters } = useStore()
const c = computed(() => getters.juniorPage( 'faq'))
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .faq-hangman {
    @media only screen and (min-width: 0) {
      margin-top: 50px;

      > div {
        position: relative;

        h2 {
          margin-left: 23px;
        }

        h2 + img {
          width: 66px;
          height: 99px;
          position: absolute;
          right: 20px;
          top: 10px;
        }

        .container {
          background-color: $blue2;
          padding: 50px 23px 20px 23px;
          border-radius: 25px;

          .faq-item {
            div {
              img {
                width: 21px;
                height: 30px;
                margin-right: 5px;
                display: flex;
              }

              span {
                font-size: 2.4rem;
                color: white;
              }
            }

            p {
              color: white;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {}

    @media only screen and (min-width: 1024px) {}

    @media only screen and (min-width: 1440px) {
      width: 888px;
      margin: 0 auto;

      > div {
        h2 {
          margin: 70px 0 0 520px;
        }
      }
    }
  }
</style>
<template>
  <section class="txto-community">
    <div>
      <h2>{{ c.title }}</h2>
      <p>{{ c.p }}</p>
      <SocialMediaLinks />
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import SocialMediaLinks from "@/components/SocialMediaLinks";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('community'))
</script>

<style lang="scss">
  .txto-community {
    @media only screen and (min-width: 0) {
      margin: 70px 23px 30px 23px;
      div {
        p {
          margin-bottom: 25px;
          color: #2B4444;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      div {
        h2 {
          font-size: 2.4rem;
        }

        p {
          margin-right: 30px;
          font-size: 1.6rem;
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      div {
        p {
          margin-right: 80px;
        }
      }
    }
  }
</style>
<template>
  <img class="blue-design" src="../assets/blue-design.png" alt="" />
  <footer class="footer-hangman">
    <div>
      <ul>
        <li v-for="page in c.pages" :key="page.id">
          <a :href="'#' + page.id" @click.prevent="scrollToSection(page.id)">{{ page.title }}</a>
        </li>
      </ul>
      <img src="../assets/logo-txto.png" alt="" />
    </div>
    <img src="../assets/man.png" alt="" />
  </footer>
</template>

<script setup>
  import {computed, inject} from 'vue';
  import { useStore } from 'vuex'

  const { getters } = useStore()
  const c = computed(() => getters.hangmanPage( 'navigation'))

  const smoothScroll = inject('smoothScroll')

  function scrollToSection (id) {
    const elm = document.getElementById(id);
    smoothScroll({
      scrollTo: elm,
      offset: -60
    })
  }
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .blue-design {
    height: 41px;
  }

  .footer-hangman {
    @media only screen and (min-width: 0) {
      height: 445px;
      margin-top: 30px;
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $blue2;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      position: relative;

      > div {
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          list-style: none;

          li {
            margin-bottom: 18px;

            a {
              padding: 5px 15px;
              font-size: 1.4rem;
              font-weight: 900;
              text-decoration: none;
              background-color: $beige;
              border-radius: 10px;
              cursor: pointer;
            }
          }
        }

        img {
          display: none;
        }
      }

      div + img {
        width: 118px;
        height: 167px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    @media only screen and (min-width: 768px) {
      height: 119px;
      margin-top: 70px;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-around;
        //position: relative;

        ul {
          width: 500px;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          column-gap: 15px;
        }

        img {
          width: 58px;
          height: 22px;
          display: flex;
          position: absolute;
          right: 80px;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      height: 158px;
      margin-top: 90px;

      > div {
        ul {
          width: 550px;

          li {
            a {
              font-size: 1.6rem;
            }
          }
        }
      }

      div + img {
        width: 157px;
        height: 223px;
      }
    }

    @media only screen and (min-width: 1440px) {
      > div {
        ul {
          width: 100%;
        }
      }
    }
  }
</style>
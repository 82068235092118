<template>
  <footer class="footer-junior">
    <div>
      <img src="../assets/logo-junior.png" alt="" />
      <ul>
        <li v-for="page in c.pages" :key="page.id">
          <a :href="'#' + page.id" @click.prevent="scrollToSection(page.id)">{{ page.title }}</a>
        </li>
      </ul>
      <div class="align">
        <img src="../assets/logo-junior.png" alt="" />
        <div class="social-media-links">
          <img src="../assets/icons/insta.png" alt="" />
          <img src="../assets/icons/fb.png" alt="" />
          <img src="../assets/icons/yt.png" alt="" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
  import {computed, inject} from 'vue';
  import { useStore } from 'vuex'

  const { getters } = useStore()
  const c = computed(() => getters.juniorPage( 'navigation'))

  const smoothScroll = inject('smoothScroll')

  function scrollToSection (id) {
    const elm = document.getElementById(id);
    smoothScroll({
      scrollTo: elm,
      offset: -60
    })
  }
</script>

<style lang="scss">
  .footer-junior {
    @import '../styles/vars.scss';

    @media only screen and (min-width: 0) {
      margin-top: 100px;
      background-color: $blue2;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 145px;
          height: 84px;
          margin-top: -40px;
        }

        ul {
          margin: 25px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          list-style: none;

          li {
            margin-top: 10px;
            a {
              font-weight: 700;
              text-decoration: none;
              color: white;
            }
          }
        }

        .align {
          > img {
            display: none;
          }

          .social-media-links {
            margin: 30px 0;

            img {
              width: 40px;
              height: 40px;
              margin: 0 8px;
              cursor: pointer;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      > div {
        > img {
          display: none;
        }

        ul {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;

          li {
            margin: 0 8px;
          }
        }

        .align {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > img {
            margin: 0 0 0 20px;
            display: flex;
          }

          .social-media-links {
            margin-right: 20px;
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {}

    @media only screen and (min-width: 1440px) {
      margin-top: 250px;

      > div {
        .align {
          > img {
            margin-left: 50px;
          }

          .social-media-links {
            margin-right: 60px;
          }
        }
      }
    }
  }
</style>
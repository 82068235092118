<template>
  <section id="about" class="explore-hangman-page">
    <div>
      <h2>{{ c.title }}</h2>
      <img src="../assets/explore-img.png" alt="Exploreaza lumea cuvintelor cu Hangman" />
      <div class="align">
        <img src="../assets/hangman3.png" alt="Jocul preferat Hangman" />
        <p>{{ c.p1 }}</p>
      </div>
      <p>{{ c.p2 }}</p>
      <p>{{ c.p3 }}</p>
      <img src="../assets/hangman3.png" alt="Jocul preferat Hangman" />
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import BaseButton from "@/components/BaseButton";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('exploreHangman'))
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .explore-hangman-page {
    @media only screen and (min-width: 0) {
      margin: 50px 23px 20px 23px;

      * {
        font-family: "Texturina", sans-serif;
        color: $brown;
      }

      > div {
        h2 {
          font-weight: 900;
        }

        h2 + img {
          width: 169px;
          height: 164px;
          margin-top: -20px;
          left: 0;
          position: absolute;
        }

        .align {
          img {
            display: none;
          }
        }

        p {
          margin-bottom: 25px;
        }

        p + img {
          display: none;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 0 20px 0 0;

      > div {
        h2 {
          margin-top: 0;
        }

        p {
          margin-bottom: -10px;
        }

        p + img {
          width: 103px;
          height: 73px;
          margin-left: -20px;
          display: flex;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      > div {
        h2 {
          margin-bottom: 0;
        }

        .align {
          display: flex;

          img {
            width: 161px;
            height: 114px;
            margin: 10px -20px 0 -30px;
            display: flex;
            z-index: 1;
          }
        }

        p + img {
          display: none;
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      > div {
        h2 + img {
          margin-top: 130px;
        }

        .align {
          img {
            margin-right: 20px;
          }
        }
      }
    }
  }
</style>
<template>
  <section id="whats-inside" class="whats-inside">
    <img class="first-shape" src="../assets/blue-shape2.png" alt="">
    <img class="second-shape" src="../assets/blue-shape3.png" alt="">
    <div>
      <h2>{{ c.title }}</h2>
      <p>{{ c.p }}</p>
      <img src="../assets/whats-inside.png" alt="" />
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('whatsInside'))
</script>

<style lang="scss">
  .whats-inside {
    @media only screen and (min-width: 0) {
      margin: 30px 0 20px 0;
      //background-image: url("../assets/blue-shape2.png");
      //background-size: cover;
      //max-height: 430px;
      overflow: hidden;
      position: relative;

      .first-shape {
        width: 100%;
        max-height: 366px;
        position: absolute;
      }

      .second-shape {
        display: none;
      }

      div {
        margin: 0 23px;
        display: flex;
        flex-direction: column;
        position: relative;
        color: white;

        h2 {
         padding-top: 50px;
        }

        p {
          margin: 0;
        }

        img {
          width: 159px;
          height: 196px;
          margin-top: 30px;
          align-self: center;
          position: relative;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      .first-shape {
        display: none;
      }

      .second-shape {
        width: 100%;
        max-height: 366px;
        display: block;
        position: absolute;
      }

      div {
        padding-top: 10px;

        p {
          max-width: 330px;
        }

        img {
          width: 178px;
          height: 219px;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .second-shape {
        max-height: 440px;
      }

      div {
        h2, p {
          margin-right: 40px;
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      display: flex;
      align-items: center;
      justify-content: center;

      .second-shape {
        max-height: 470px;
      }

      div {
        h2 {
          padding-top: 100px;
        }
      }
    }
  }
</style>
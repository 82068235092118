<template>
  <section id="games-hangman" class="three-games">
    <div>
      <div class="title">
        <span>{{ c.three }}</span>
        <span>{{ c.game }}</span>
      </div>
      <div>
        <ThreeGamesItem
            v-for="(game, index) in c.games"
            :key="index"
            :img="game.img"
            :altText="game.altText"
            :title="game.title"
            :p="game.p"
            :class="{
              'first-item': index === 0,
              'second-item': index === 1,
              'third-item': index === 2
            }"/>
      </div>
      <button><span>{{ c.cta }}<img src="../assets/icons/discover-hangman.png" alt="" /></span></button>
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import ThreeGamesItem from "@/components/ThreeGamesItem";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('threeGames'))
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .three-games {
    @media only screen and (min-width: 0) {
      margin: 10px 23px 30px 23px;

      * {
        font-family: "Texturina", sans-serif;
        color: $brown;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          height: 100px;
          display: flex;
          align-items: end;
          justify-content: center;

          span {
            font-weight: 900;
          }

          span:first-child {
            font-size: 8rem;
            align-self: center;
          }

          span:last-child {
            width: 80px;
            margin-left: 10px;
            font-size: 2.4rem;
          }
        }

        .first-item {
          display: flex;
          flex-direction: column;
          align-items: center;

          div {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
              text-align: center;
            }
          }

          img {
            width: 79px;
            height: 61px;
            margin: 35px 0 10px 0;
          }
        }

        .second-item {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;

          img {
            width: 96px;
            height: 116px;
            margin-left: 20px;
          }
        }

        .third-item {
          display: flex;
          flex-direction: column;
          align-items: center;

          div {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
              text-align: center;
            }
          }

          img {
            width: 71px;
            height: 62px;
            margin-bottom: 10px;
          }
        }

        button {
          font-family: "Outfit", sans-serif;
          background: $dark-beige;
          border: none;
          padding: 0;
          cursor: pointer;
          outline-offset: 4px;
          clip-path: polygon(10px 0, 100% 0, calc(100% - 10px) 100%, 0 100%);
          border-radius: 95px 30px 60px 20px;

          span {
            padding: 12px 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $beige;
            color: white;
            font-weight: 900;
            transform: translate(-6px, -6px);
            clip-path: polygon(10px 0, 100% 0, calc(100% - 10px) 100%, 0 100%);
            border-radius: 95px 20px 50px 20px;
          }

          img {
            width: 24px;
            height: 24px;
            margin-left: 15px;
          }
        }

        button:active span {
          transform: translateY(-2px);
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 0 16px 30px 16px;

      > div {
        .title {
          height: 150px;
          margin-bottom: 40px;

          span:first-child {
            font-size: 13rem;
            display: flex;
            align-self: center;
          }

          span:last-child {
            font-size: 3rem;
          }
        }

        .title + div {
          display: flex;
          gap: 30px;
        }

        .first-item {
          div {
            align-items: start;

            p {
              text-align: left;
            }
          }

          img {
            width: 103px;
            height: 96px;
            margin-top: 0;
            align-self: start;
          }
        }

        .second-item {
          flex-direction: column;

          div {
            align-items: start;
          }

          img {
            width: 79px;
            height: 95px;
            margin: 0 0 7px 0;
            align-self: start;
          }
        }

        .third-item {
          div {
            align-items: start;

            p {
              text-align: left;
            }
          }

          img {
            width: 102px;
            height: 92px;
            align-self: start;
          }
        }

      }
    }

    @media only screen and (min-width: 1024px) {
      > div {
        button {
          span {
            font-size: 2rem;
          }

        }
      }
    }

    @media only screen and (min-width: 1440px) {
      margin: 0 48px 30px 48px;
    }
  }
</style>
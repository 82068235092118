<template>
  <section id="created-by" class="created-by">
    <div>
      <h2>{{ c.title }}</h2>
      <p>{{ c.p1 }}</p>
      <p>{{ c.p2 }}</p>
      <p>{{ c.p3 }}</p>
      <p>{{ c.p4 }}</p>
      <p>{{ c.p5 }}</p>
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.juniorPage( 'createdBy'))
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .created-by {
    @media only screen and (min-width: 0) {
      margin: 50px 23px 0 23px;

      > div {
        p {
          color: $dark-green;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 50px 16px 0 16px;

      > div {
        h2 {
          padding: 10px 16px;
        }

        p {
          width: 607px;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      margin: 70px 185px 0 185px;

      > div {
        p {
          width: 100%;
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      margin: 70px 276px 0 276px;
    }
  }
</style>
<template>
  <section id="punishment" class="punsihment-cards">
    <div>
      <h2>{{ c.title }}</h2>
      <CarouselTwo :slides="slides" />
    </div>
  </section>
</template>

<script setup>
  import {computed, ref} from "vue";
  import {useStore} from "vuex";
  import CarouselTwo from "@/components/CarouselTwo";

  const { getters } = useStore()
  const c = computed(() => getters.hangmanPage( 'punishmentCards'))

  const slides = ref([
    { id: 1, src: "./cards/4.png", alt: 'Txto stimuleaza gandirea' },
    { id: 2, src: "./cards/5.png", alt: 'Txto stimuleaza empatia' },
    { id: 3, src: "./cards/6.png", alt: 'Txto stimuleaza buna dispozitie' }
  ]);
</script>

<style lang="scss">
  .punsihment-cards {
    @media only screen and (min-width: 0) {
      margin-top: 50px;
      overflow: hidden;

      > div {
        margin: 20px 23px;

        h2 {
          margin-bottom: 40px;
          text-align: center;
        }
      }
    }

    @media only screen and (min-width: 768px) {}

    @media only screen and (min-width: 1024px) {}

    @media only screen and (min-width: 1440px) {}
  }
</style>
<template>
  <section class="fav-game">
    <img class="title" src="../assets/favGame-title.png" alt="Jocul preferat al aztecilor" />
    <div>
      <img src="../assets/hangman1.png" alt="Hangman jocul preferat al aztecilor" />
      <div>
        <img src="../assets/hangman2.png" alt="Jocul preferat al aztecilor Hangman" />
        <img src="../assets/hangman3.png" alt="Jocul preferat Hangman" />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  .fav-game {
    @media only screen and (min-width: 0) {
      margin: 70px 23px 20px 23px;

      .title {
        width: 274px;
        height: 156px;
      }

      div {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 272px;
          height: 237px;
        }

        div {
          margin: -65px 0 0 30px;
          display: flex;
          flex-direction: row;

          img:first-child {
            width: 144px;
            height: 92px;
          }

          img:last-child {
            width: 158px;
            height: 100px;
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin-top: 0;

      .title {
        display: none;
      }

      div {
        margin-right: -115px;
        margin-left: -40px;
        align-items: baseline;

        img {
          width: 482px;
          height: 420px;
        }

        div {
          margin-top: -110px;

          img:first-child {
            width: 197px;
            height: 126px;
          }

          img:last-child {
            display: none;
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      div {
        margin-right: -160px;

        img {
          width: 654px;
          height: 570px;
        }

        div {
          margin-top: -180px;

          img:first-child {
            width: 310px;
            height: 199px;
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {}
  }
</style>
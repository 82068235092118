<template>
  <section id="game-presentation" class="game-presentation">
    <div>
      <div class="text">
        <p>{{ c.p1 }}</p>
        <p>{{ c.p2 }}</p>
      </div>
      <Carousel :slides="slides" />
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import Carousel from "@/components/Carousel";

  const { getters } = useStore()
  const c = computed(() => getters.juniorPage( 'presentation'))

  const slides = [
    { id: 1, src: "./cards/1.png", alt: 'Txto stimuleaza gandirea' },
    { id: 2, src: "./cards/2.png", alt: 'Txto stimuleaza empatia' },
    { id: 3, src: "./cards/3.png", alt: 'Txto stimuleaza buna dispozitie' }
  ];
</script>

<style lang="scss">
  .game-presentation {
    @media only screen and (min-width: 0) {
      margin: 50px 23px 0 23px;

      > div {
        .carousel {
          margin-top: 40px;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 50px 16px 0 16px;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 40px;

        .carousel {
          margin-top: 0;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      margin: 50px 101px 0 101px;

      > div {
        gap: 35px;
      }
    }

    @media only screen and (min-width: 1440px) {
      margin: 50px 48px 0 48px;

      > div {
        align-items: start;

        .carousel {
          .carousel-wrapper {
            img {
              width: 669px;
              height: 593px;
            }

            .align-carousel {
              .carousel {
                width: 379px;

                .slide-img {
                  height: 563px;
                }
              }

              .carousel-button {
                margin-top: 50px;

                img {
                  width: 89px;
                  height: 89px;
                }
              }

              .left {
                margin-right: 30px;
              }

              .right {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
</style>
<template>
  <section class="knowledge-fun">
    <div>
      <img class="title" src="../assets/kfun-title.png" alt="" />
      <div>
        <div class="images">
          <img src="../assets/blue-kid.png" alt="" />
          <img src="../assets/txto-pack.png" alt="" />
        </div>
        <div class="text">
          <h2>{{ c.title }}</h2>
          <div class="align">
            <img src="../assets/txto-junior.png" alt="" />
            <p>{{ c.p1 }}</p>
          </div>
          <p>{{ c.p2 }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('kForFun'))
</script>

<style lang="scss">
  .knowledge-fun {
    @media only screen and (min-width: 0) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: #203334;

      > div {
        margin: 30px 23px 0 23px;

        .title {
          width: 273px;
          height: 104px;
          margin-bottom: 25px;
        }

        h2 {
          display: none;
        }

        .images {
          display: flex;
          flex-direction: column;
          align-items: center;

          img:first-child {
            width: 273px;
            height: 243px;
          }

          img:last-child {
            width: 170px;
            height: 153px;
            margin-top: -105px;
            margin-left: -110px;
          }
        }

        .text {
          .align {
            display: flex;

            img {
              width: 160px;
              height: 107px;
              margin-left: -15px;
              margin-top: 15px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      > div {
        margin: 0 16px 0 16px;

        .title {
          display: none;
        }

        img + div {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;

          .images {
            margin-right: -45px;

            img:first-child {
              width: 403px;
              height: 359px;
            }

            img:last-child {
              width: 210px;
              height: 189px;
              margin-top: -170px;
              margin-left: -190px;
            }
          }

          .text {
            margin-right: 50px;

            h2 {
              max-width: 330px;
              margin: 0 0 10px 0;
              display: block;
              font-family: "Candal", sans-serif;
              font-size: 4.8rem;
              background-image: radial-gradient(115.2% 115.2% at 39.33% 24%, #00A6DB 0%, #52E0FF 50.5%, #008C9E 100%);
              -webkit-background-clip: text;
              color: transparent;
              -webkit-text-fill-color: transparent;
            }

            div + p {
              margin-top: 0;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      > div {
        img + div {
          .images {
            margin-right: -75px;

            img:first-child {
              width: 561px;
              height: 499px;
            }

            img:last-child {
              width: 260px;
              height: 234px;
              margin-top: -170px;
              margin-left: -190px;
            }
          }

          .text {
            h2 {
              max-width: 480px;
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      > div {
        margin: 0 48px;

        img + div {
          .images {
            margin-right: 0;

            img:first-child {
              width: 660px;
              height: 587px;
            }

            img:last-child {
              width: 307px;
              height: 276px;
              margin-top: -280px;
              margin-left: -320px;
            }
          }

          .text {
            h2 {
              max-width: 650px;
              margin-bottom: 70px;
            }

            .align {
              img {
                width: 226px;
                height: 151px;
              }
            }

            div + p {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
</style>
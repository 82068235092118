export const content = {
  ro: {
    landingPage: {
      hero: {
        title: 'PLAY TIME :D',
        subtitle: 'Locul tău pentru jocuri distractive pe bază de cărți unde toți copiii, indiferent de vârstă, se pot distra în timp ce-și dezvoltă imaginația și inteligența. ',
        cta: {
          title: 'Alegeți jocul',
          game1: 'TXTO Junior',
          game2: 'Hangman'
        }
      },
      kForFun: {
        title: 'Knowledge for fun',
        p1: 'TXTO Junior este un joc de carti foarte distractiv pe care copiii il iubesc, pentru ca le stimuleaza gandirea,  empatia si buna dispozitie.',
        p2: 'Jocul  este foarte versatil datorita faptului ca se pot juca mai multe jocuri cu un singur pachet de carti.'
      },
      gamesIncluded: {
        title: 'Jocurile incluse in pachet',
        games: [
          {
            id: 1,
            title: 'Names',
            p: 'Scopul jocului este sa formezi numele folosind oricarui jucator folosind 4 litere consecutive!',
          },
          {
            id: 2,
            title: 'Fazan',
            p: 'Scopul jocului este sa formezi numele folosind oricarui jucator folosind 4 litere consecutive!',
          },
          {
            id: 3,
            title: 'Chronos',
            p: 'Scopul jocului este sa formezi numele folosind oricarui jucator folosind 4 litere consecutive!',
          },
        ]
      },
      whatsInside: {
        title: 'Ce conține jocul TXTO?',
        p: 'Pachetul contine un set de carti cu toate literele alfabetului, si are un set de reguli pentru 3 jocuri + mini markerul TXTO!'
      },
      testimonials: {
        title: 'Iată și câteva păreri',
        comments: [
          {
            img: './images/boy.png',
            altText: 'TXTO este un joc distractiv',
            comment: 'Am jucat TXTO prima oara si mi-a placut ca am castigat!',
            author: 'Sorin (7 ani)',
          },
          {
            img: './images/girl.png',
            altText: 'TXTO este un joc distractiv pentru copii',
            comment: 'E cel mai cool joc de cuvinte ce l-am jucat vreodata!',
            author: 'Alice (9 ani)',
          },
        ]
      },
      whyToPlay: {
        title: 'De ce joacă copiii TXTO Junior?',
        reasons: [
          {
            img: './images/reason1.png',
            altText: 'De ce joaca copiii TXTO?',
            title: 'Pentru dezvoltarea inteligenței emoționale',
            p: 'TXTO este creat special sa dezvolte inteligenta emotionala si creativitatea. Copiii sunt stimulati sa creeze noi cuvinte si implicit se creeaza noi conexiuni mentale.',
          },
          {
            img: './images/reason2.png',
            altText: 'De ce sa joci TXTO?',
            title: 'Pentru socializare si prieteni noi',
            p: 'Cu siguranta va veti face prieteni noi si veti fi mai aproape jucand TXTO.',
          },
        ],
        cta: 'Descoperă jocul',
      },
      exploreHangman: {
        newTitle: 'Jocul preferat al aztecilor',
        title: 'Exploreaza lumea cuvintelor cu Hangman, jocul de carti ce iti pune imaginatia la incercare!',
        p1: 'Hangman este un joc foarte distractiv de care nu te poti plictisi niciodata, si care te ajuta sa iti faci prieteni noi!',
        p2: 'Un joc de carti foarte inteligent ce a fost creat special pentru a iti dezvolta vocabularul si a te relaxa.',
        p3: 'Desi initial a fost creat pentru elevi, a fost apoi dezvoltat si pentru liceeni, studenti si adulti. Practic acest joc nu are limita de varsta.',
        cta: 'Cumpără',
      },
      threeGames: {
        three: '3',
        game: 'variante de joc',
        games: [
          {
            img: './images/game1.png',
            altText: 'SHUFFLE TOWER',
            title: 'SHUFFLE TOWER',
            p: 'Chance and luck does count, but agility and a developed vocabulary are the key to win.',
          },
          {
            img: './images/game2.png',
            altText: 'HANGMAN',
            title: 'H_NGM_N',
            p: 'The role-playing game by excellence to spend unforgettable moments with friends.',
          },
          {
            img: './images/game3.png',
            altText: 'RUSSIAN ROULETTE',
            title: 'RUSSIAN ROULETTE',
            p: 'The role-playing game by excellence to spend unforgettable moments with friends.',
          },
        ],
        cta: 'Descoperă Hangman',
      },
      contact: {
        title: 'Contact',
        p: `Ne puteti contacta la nr. de telefon <b>0743 536 495</b> sau pe adresa de email: <b>txto@txto.eu</b> sau folosind formularul de mai jos:`,
        email: 'e-mail',
        message: 'mesaj către TXTO',
        cta: 'Trimite',
      },
      community: {
        title: 'Comunitatea TXTO',
        p: 'Am creat comunitatea TXTO pentru a fi mai aproape si pentru a da mai departe din experientele placute ale copiiilor ce joaca texto. Comunitatea este atat pe Instagram, Facebook si Youtube si are rolul de a va informa despre noile jocuri lansate si despre cum TXTO reuseste sa aduca mai multe experiente placute!',
      },
      footer: {
        pages: [
          { id: 'home', title: 'Despre' },
          { id: 'txto-junior', title: 'TXTO Junior' },
          { id: 'hangman', title: 'Hangman' },
        ]
      }
    },
    junior: {
      navigation: {
        pages: [
          { id: 'home', title: 'Home' },
          { id: 'game-presentation', title: 'Ce este TXTO?' },
          { id: 'games', title: 'Jocuri' },
          { id: 'why-to-play', title: 'Beneficii' },
          { id: 'testimonials', title: 'Păreri/Testimoniale' },
          { id: 'whats-inside', title: 'Ce conține pachetul TXTO' },
          { id: 'faq', title: 'Întrebări frecvente' },
          { id: 'price', title: 'Preț și detalii cumpărare' },
          { id: 'created-by', title: 'Despre creator' },
          { id: 'contact-junior', title: 'Contact' },
        ]
      },
      hero: {
        title: 'TXTO, jocul cuvintelor, al imaginatiei, si al creativitatii!',
        p: 'Creat special pentru dezvoltarea armonioasa a copiilor, si pentru a lasa amintiri copilaresti!',
        cta: 'Cadou TXTO!',
      },
      presentation: {
        p1: 'TXTO este un joc de carti foarte distractiv pe care copiii il iubesc, pentru ca le stimuleaza gandirea,  empatia si buna dispozitie.',
        p2: 'Conceptul jocului a fost creat in 2009 de catre Dragos Velicu, si de atunci a fost rafinat in varianta TXTO Junior, special pentru copiii intre 6 si 14 ani.',
      },
      package: {
        p: 'TXTO este foarte versatil datorita faptului ca se pot juca mai multe jocuri cu un singur pachet de carti.',
        cta: 'Întră in joc',
      },
      txtoGames: {
        title: 'Jocurile TXTO',
        gamesOne: [
          {
            id: 1,
            title: 'Names',
            p: `Scopul jocului este sa formezi numele folosind oricarui jucator folosind 4 litere consecutive!
              • Fiecare jucätor isi scrie numele cu markerul special, pe eticheta reutilizabila si o pozitioneaza cât mai vizibil pe bluza sa.
               Se distribuie 4 carti fiecarui jucator; restul cartilor sunt asezate in pachet pe, masa, literele find in jos.
              • Fiecare jucator va incerca sã indeparteze adversarii din joc formând oricare ansamblu de 4 litere consecutive din numele acestora: de exemplu, pentru a o elimina pe Andreea vei avea nevoie de oricare din combinatiile ANDR, NDRE, DREE sau REEA.`,
          },
        ],
        gamesTwo: [
          {
            id: 2,
            title: 'Fazan',
            p: `Scopul jocului este sa formezi numele folosind oricarui jucator folosind 4 litere consecutive!
              • Fiecare jucätor isi scrie numele cu markerul special, pe eticheta reutilizabila si o pozitioneaza cât mai vizibil pe bluza sa.
               Se distribuie 4 carti fiecarui jucator; restul cartilor sunt asezate in pachet pe, masa, literele find in jos.
              • Fiecare jucator va incerca sã indeparteze adversarii din joc formând oricare ansamblu de 4 litere consecutive din numele acestora: de exemplu, pentru a o elimina pe Andreea vei avea nevoie de oricare din combinatiile ANDR, NDRE, DREE sau REEA.`,
          },
          {
            id: 3,
            title: 'Chronos',
            p: `Scopul jocului este sa formezi numele folosind oricarui jucator folosind 4 litere consecutive!
              • Fiecare jucätor isi scrie numele cu markerul special, pe eticheta reutilizabila si o pozitioneaza cât mai vizibil pe bluza sa.
               Se distribuie 4 carti fiecarui jucator; restul cartilor sunt asezate in pachet pe, masa, literele find in jos.
              • Fiecare jucator va incerca sã indeparteze adversarii din joc formând oricare ansamblu de 4 litere consecutive din numele acestora: de exemplu, pentru a o elimina pe Andreea vei avea nevoie de oricare din combinatiile ANDR, NDRE, DREE sau REEA.`,
          },
        ]
      },
      faq: {
        title: 'Întrebări frecvente',
        questions: [
          {
            question: 'Câte carti are TXTO?',
            answer: 'Pachetul contine 72 de carti ce au desenate toate literele alfabetului.',
          },
          {
            question: 'Se poate returna?',
            answer: 'Da, desigur. Trebuie doar sa ne trimiteti inapoi pachetul de carti la adresa specificata in sectiunea contact.',
          },
          {
            question: 'Cate jocuri are TXTO?',
            answer: 'Pachetul contine carti cu toate literele alfabetului, si are un set de reguli pentru 3 jocuri.',
          },
        ]
      },
      price: {
        title: 'Preț și opțiuni de cumpărare',
        subTitle: `Un joc TXTO Junior costa <b>60 RON</b>`,
        p1: `Daca veti cumpara doua jocuri, <b>transportul va fi gratuit</b>.`,
        p2: `Iar la trei sau mai multe jocuri cumparate, aveti <b>transport gratuit si discount de 15%</b>, deci veti plati in total 165 RON.`,
        cta: 'Cumpără TXTO',
      },
      createdBy: {
        title: 'Cine a creat TXTO',
        p1: 'Salut, ma numesc Dragos Velicu, si am creat jocul TXTO sub dorinta unui imbold creativ. Fiind fascinat de educatie si cultura imi doream sa pot oferi copiilor un joc care sa ii dezvolte, sa ii creasca asa cum am crescut si eu, cu amintirea multor jocuri din copilarie.',
        p2: 'In acelasi timp doresc sa perpetuam valorile culturale si apetitul nostru pentru jocuri distractive si educative.',
        p3: 'La inceput nu aveam carti ci erau cateva hartiute cu litere. Am reusit sa o conving pe matusa mea sa accepte sa ii arat jocul. Asa a pornit totul.',
        p4: 'Apoi lucrurile au evoluat si in 2014 a fost gata primul joc TXTO sub forma pachetului de carti.',
        p5: 'Dorinta mea profunda este sa ajut copiii sa se joace placut si intr-un mod in care sa ii imbogateasca, sa ii ridice. ',
      }
    },
    hangman: {
      navigation: {
        pages: [
          { id: 'home-hangman', title: 'Home' },
          { id: 'rules', title: 'Regulile jocului!' },
          { id: 'about', title: 'Despre joc' },
          { id: 'punishment', title: 'Cărțile pedepse' },
          { id: 'faq-hangman', title: 'Întrebari frecvente' },
          { id: 'games-hangman', title: 'Jocuri incluse' },
          { id: 'contact-hangman', title: 'Contact' },
        ]
      },
      easyPlay: {
        title: 'Simplu de jucat!',
        p: 'Urmărește videoclipul cu instrucțiunile de joc și ești gata de distracție!',
      },
      punishmentCards: {
        title: 'Momente hilare cu multitudinea de cărți pedepse',
        p: 'Două ture de trecut sub masă legat la ochi',
      },
      socialMedia: {
        title: 'Ne găsiți si pe social media!',
      },
    },
  },
  en: {},
}
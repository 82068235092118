<template>
  <section class="contact">
    <h2>{{ c.title }}</h2>
    <img class="title" src="../assets/contact-title.png" alt="Contact Txto" />
    <div class="contact-container">
      <p v-html="c.p" />
      <div class="container">
        <div v-if="!submitted">
          <form @submit.prevent="">
            <label for="email">
              <input id="email" type="text"
                     :placeholder="c.email" />
            </label>
            <label for="msg">
              <textarea id="msg"
                        :placeholder="c.message" />
            </label>
            <button>{{ c.cta }}</button>
          </form>
        </div>

        <div v-else>
          <p class="thank-you" v-html="c.thankyouMessage" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import {computed, ref} from "vue";
import {useStore} from "vuex";

const { getters } = useStore()
const c = computed(() => getters.sectionContent('contact'))

const submitted = ref(false);
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .contact {
    @media only screen and (min-width: 0) {
      margin: 70px 0 30px 0;

      h2 {
        display: none;
      }

      .title {
        width: 212px;
        height: 35px;
        margin: 0 23px;
      }

      .contact-container {
        p {
          margin: 25px 46px 20px 46px;
          font-family: "Texturina", sans-serif;
          font-size: 1.4rem;
        }

        .container {
          background-image: url("../assets/contact-image.png");
          background-size: cover;
          height: 291px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          border-bottom-right-radius: 30px;
          border-bottom-left-radius: 30px;

          div {
            z-index: 1;
          }

          form {
            display: flex;
            flex-direction: column;
            justify-content: center;

            input, textarea {
              width: 274px;
              margin-bottom: 20px;
              border: none;
              border-radius: 10px;
            }

            input {
              height: 40px;
            }

            textarea {
              height: 149px;
            }

            input::placeholder, textarea::placeholder {
              padding-left: 10px;
              color: #4B350A;
            }

            button {
              padding: 10px 25px;
              margin-top: 290px;
              position: absolute;
              border-radius: 16px;
              background-color: $orange;
              color: white;
              cursor: pointer;
              z-index: 1;
            }
          }
        }

        .container::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(0, 166, 219, 0) 26%, rgba(0, 166, 219, 0.62) 64%);
          border-bottom-right-radius: 30px;
          border-bottom-left-radius: 30px;
          pointer-events: none;
          z-index: 0;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 70px 16px 30px 16px;

      h2 {
        display: flex;
        font-family: "Candal", sans-serif;
        font-size: 4.8rem;
        background-image: radial-gradient(115.2% 115.2% at 39.33% 24%, #00A6DB 0%, #52E0FF 50.5%, #008C9E 100%);
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
      }

      .title {
        display: none;
      }

      .contact-container {
        display: flex;
        justify-content: space-between;

        p {
          max-width:35%;
          margin: 0 10px 0 0;
        }

        .container {
          width: 486px;
          height: 426px;

          form {
            input, textarea {
              width: 438px;
            }

            textarea {
              height: 248px;
              margin-top: 30px;
            }

            button {
              margin-top: 420px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      .contact-container {
        p {
          padding-right: 30px;
        }

        .container {
          width: 651px;
          height: 426px;

          form {
            input, textarea {
              width: 600px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      margin: 70px 48px 30px 48px;

      .contact-container {
        .container {
          width: 888px;
          height: 426px;

          form {
            input, textarea {
              width: 829px;
            }

          }
        }
      }
    }
  }
</style>
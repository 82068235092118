<template>
  <section id="contact-hangman" class="contact-hangman">
    <div>
      <h2>{{ c.title }}</h2>
      <img src="../assets/contact-image3.png" alt="" />
      <div class="container">
        <p v-html="c.p" />

        <div v-if="!submitted">
          <form @submit.prevent="">
            <input id="email" type="text"
                   :placeholder="c.email" />
            <textarea id="msg"
                      :placeholder="c.message" />
            <BaseButton :text="c.cta" />
          </form>
        </div>

        <div v-else>
          <p class="thank-you" v-html="c.thankyouMessage" />
        </div>
      </div>

      <SocialMediaHangman />
    </div>
  </section>
</template>

<script setup>
  import {computed, ref} from "vue";
  import {useStore} from "vuex";
  import BaseButton from "@/components/BaseButton";
  import SocialMediaHangman from "@/components/SocialMediaHangman";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('contact'))

  const submitted = ref(false);
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .contact-hangman {
    @media only screen and (min-width: 0) {
      > div {
        margin: 0 23px;
        position: relative;

        > h2 {
          margin-left: 22px;
        }
        
        h2 + img {
          width: 89px;
          height: 77px;
          position: absolute;
          top: 20px;
          right: 30px;
        }

        .container {
          padding: 50px 0 20px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 25px;
          background-color: $blue2;

          p {
            width: 85%;
            max-width: 450px;
            font-size: 1.4rem;
            color: white;
          }

          b {
            color: white;
          }

          div {
            width: 85%;
            max-width: 450px;

            form {
              display: flex;
              flex-direction: column;
              align-items: center;

              input, textarea {
                width: 100%;
                max-width: 450px;
                margin-bottom: 20px;
                border: none;
                border-radius: 10px;
              }

              input::placeholder, textarea::placeholder {
                padding-left: 15px;
                font-size: 1.4rem;
              }

              input {
                height: 30px;
              }

              textarea {
                height: 136px;
              }

              .base-button {
                padding: 6px 15px;
                align-self: flex-start;
                font-size: 1.4rem;
                font-weight: 900;
                background-color: $beige;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      > div {
        .container {
          p {
            width: 100%;
            max-width: 482px;
          }

          div {
            width: 100%;
            max-width: 482px;

            form {
              input, textarea {
                width: 482px;
                max-width: 482px;
              }
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      > div {
        margin: 0 185px;
      }
    }

    @media only screen and (min-width: 1440px) {
      > div {
        width: 888px;
        margin: 0 auto;

        > h2 {
          margin: 20px 0 0 610px;
        }

        .container {
          p {
            max-width: 771px;
          }

          div {
            max-width: 771px;

            form {
              input, textarea {
                width: 771px;
                max-width: 771px;
              }
            }
          }
        }
      }
    }
  }
</style>
<template>
  <section id="rules" class="easy-play">
    <div class="title">
      <h2>{{ c.title }}</h2>
      <img src="../assets/alien.png" alt="" />
    </div>

    <div class="text">
      <p>{{ c.p }}</p>
      <img src="../assets/play.png" alt="" />
    </div>

    <img src="../assets/video.png" alt="" />
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.hangmanPage( 'easyPlay'))
</script>

<style lang="scss">
  .easy-play {
    @media only screen and (min-width: 0) {
      margin-bottom: 70px;

      .title {
        margin-left: 23px;
        display: flex;
        position: relative;

        h2 {
          margin-bottom: 5px;
        }

        img {
          width: 89px;
          height: 77px;
          top: 20px;
          right: 23px;
          position: absolute;
        }
      }

      .text {
        height: 251px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 25px;
        background-color: #008C9E;

        p {
          margin: 55px 60px 0 60px;
          text-align: center;
          color: white;
        }

        img {
          width: 193px;
          height: 193px;
        }
      }

      div + img {
        display: none;
      }
    }

    @media only screen and (min-width: 768px) {
      .title {
        h2 {
          margin-left: 186px;
        }

        img {
          left: 56px;
        }
      }

      .text {
        height: 142px;
        margin: 0 16px;
        flex-direction: row;

        p {
          margin: 30px 52px 0 52px;
          text-align: left;
        }

        img {
          margin: 60px 40px 0 0;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      .title {
        margin: 0 185px;

        h2 {
          margin-left: 168px;
        }

        img {
          top: 40px;
          left: 39px;
        }
      }

      .text {
        margin: 0 185px;

        p {
          margin: 30px 0 0 39px;
        }

        img {
          margin-right: 10px;
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      .title {
        h2 {
          margin-left: 50px;
        }

        img {
          top: 40px;
          left: 350px;
        }
      }

      .text {
        display: none;
      }

      div + img {
        display: flex;
      }
    }
  }
</style>
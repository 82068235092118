<template>
  <section :class="['games-included-item', { 'expanded': isExpanded }]">
    <h3>{{ id + '. ' + title }}</h3>
    <p>{{ truncatedText }}</p>
    <button v-if="hasReadMore" @click="toggleExpand">{{ isExpanded ? 'Ascunde' : 'citeste mai mult' }}</button>
  </section>
</template>

<script setup>
  import { ref, computed } from 'vue';

  const props = defineProps({
    id: String,
    title: String,
    p: String,
    hasReadMore: Boolean
  });

  const isExpanded = ref(false);

  const truncatedText = computed(() => {
    if (isExpanded.value || !props.hasReadMore) {
      return props.p;
    }
    return props.p.slice(0, 100);
  });

  const toggleExpand = () => {
    isExpanded.value = !isExpanded.value;
  };
</script>

<style lang="scss">
  .games-included-item {
    @media only screen and (min-width: 0px) {
      button {
        background-color: transparent;
        border: none;
        color: #203334;
        cursor: pointer;
        padding: 0;
        font-size: 1.2rem;
        text-decoration: underline;
      }
    }

    @media only screen and (min-width: 768px) {
      p {
        margin: 0;
      }
    }
  }
</style>

<!--<script setup>-->
<!--  import {ref} from "vue";-->

<!--  const props = defineProps({-->
<!--    id: Number,-->
<!--    title: String,-->
<!--    p: String,-->
<!--    hasReadMore: Boolean,-->
<!--  })-->

<!--  const readMore = ref(false);-->

<!--  const toggleReadMore = () => {-->
<!--    readMore.value = !readMore.value;-->
<!--  };-->
<!--</script>-->


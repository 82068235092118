<template>
  <div id="home-hangman" class="hero-hangman">
    <div class="large-image-container">
      <img
          :src="largeImages[currentIndex]"
          :class="{ fadeIn: isAnimating }"
          @animationend="isAnimating = false"
      />
    </div>

    <div class="small-images-container">
      <div
          v-for="(smallImage, index) in smallImages"
          :key="index"
          @click="changeImage(index)"
      >
        <img
            :src="smallImage"
            :class="{ 'overlay': currentIndex !== index }"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  const smallImages = [
    './images/hero-small1.png',
    './images/hero-small2.png',
    './images/hero-small3.png'
  ];

  const largeImages = [
    './images/hero-hangman1.png',
    './images/hero-hangman2.png',
    './images/hero-hangman3.png'
  ];

  const currentIndex = ref(0);
  const isAnimating = ref(false);

  const changeImage = (index) => {
    if (index !== currentIndex.value) {
      isAnimating.value = true;
      currentIndex.value = index;
    }
  };
</script>

<style lang="scss">
  .hero-hangman {
    @media only screen and (min-width: 0) {
      margin: 50px 0 30px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      .large-image-container {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: opacity 0.5s ease;
        }

        img.fadeIn {
          animation: fadeIn 0.5s ease;
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }

      .small-images-container {
        display: flex;
        gap: 15px;
        justify-content: center;

        div {
          cursor: pointer;
          position: relative;

          img {
            height: 48px;
            object-fit: cover;
            transition: all 0.3s ease;
            border-radius: 8px;
          }

          img.overlay {
            filter: grayscale(100%);
            opacity: 0.6;
          }
        }

        div:hover img {
          filter: grayscale(0);
          opacity: 1;
        }

        div:nth-child(1) {
          img {
            width: 84px;
          }
        }
        div:nth-child(2) {
          img {
            width: 96px;
          }
        }
        div:nth-child(3) {
          img {
            width: 72px;
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin-top: -10px;

      .small-images-container {
        div {
          img {
            height: 69px;
          }
        }

        div:nth-child(1) {
          img {
            width: 175px;
          }
        }
        div:nth-child(2) {
          img {
            width: 204px;
          }
        }
        div:nth-child(3) {
          img {
            width: 148px;
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      margin-top: -60px;
    }

    @media only screen and (min-width: 1440px) {
      margin-top: 0;

      .large-image-container {
        width: 600px;
      }
    }
  }
</style>


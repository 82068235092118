<template>
  <section id="testimonials" class="testimonials">
    <div>
      <h2>{{ c.title }}</h2>
      <TestimonialsItem
          v-for="(testimonial, index) in c.comments"
          :key="index"
          :img="testimonial.img"
          :altText="testimonial.altText"
          :comment="testimonial.comment"
          :author="testimonial.author"
          :class="{
            'first-item': index === 0,
            'last-item': index === 1
          }"
      />
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import TestimonialsItem from "@/components/TestimonialsItem";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('testimonials'))
</script>

<style lang="scss">
  .testimonials {
    @media only screen and (min-width: 0px) {
      margin: 30px 23px 20px 23px;
      div {
        .first-item {
          flex-direction: row-reverse;
        }

        .last-item {
          text-align: right;

          p {
            margin-left: 15px;
          }
        }
      }

    }
  }
</style>
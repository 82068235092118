<template>
  <section class="games-included">
    <div>
      <div>
        <h2>{{ c.title }}</h2>
        <GamesIncludedItem v-for="game in c.games"
            :key="game.id"
            :id="game.id"
            :title="game.title"
            :p="game.p" />
      </div>
      <Carousel :slides="slides" />
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import GamesIncludedItem from "@/components/GamesIncludedItem";
  import Carousel from "./Carousel.vue";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('gamesIncluded'))

  const slides = [
    { id: 1, src: "./cards/1.png", alt: 'Txto stimuleaza gandirea' },
    { id: 2, src: "./cards/2.png", alt: 'Txto stimuleaza empatia' },
    { id: 3, src: "./cards/3.png", alt: 'Txto stimuleaza buna dispozitie' }
  ];
</script>

<style lang="scss">
  .games-included {
    @media only screen and (min-width: 0) {
      > div {
        margin: 0 23px;
        color: #203334;
      }
    }

    @media only screen and (min-width: 768px) {
      > div {
        margin: 0 16px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        > div {
          margin-left: 20px;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      > div {
        > div {
          margin-left: 30px;
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      > div {
        margin: 0 48px;
        justify-content: space-around;
      }
    }
  }
</style>
<template>
  <section id="games" class="txto-games">
    <h2>{{ c.title }}</h2>
    <div>
      <div class="first-game">
        <GamesIncludedItem v-for="(game, index) in c.gamesOne"
                           :key="game.id"
                           :id="game.id"
                           :title="game.title"
                           :p="game.p"
                           :hasReadMore="false" />
      </div>
      <div class="games">
        <GamesIncludedItem v-for="(game, index) in c.gamesTwo"
                           :key="game.id"
                           :id="game.id"
                           :title="game.title"
                           :p="game.p"
                           :hasReadMore="true" />
      </div>
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import GamesIncludedItem from "@/components/GamesIncludedItem";

  const { getters } = useStore()
  const c = computed(() => getters.juniorPage( 'txtoGames'))
</script>

<style lang="scss">
  .txto-games {
    @media only screen and (min-width: 0) {
      margin: 50px 23px 0 23px;

      > div {
        .games-included-item {
          h3 {
            margin-top: 30px;
            margin-bottom: 0;
          }

          p {
            margin-top: 10px;
          }
        }

        .games {
          .games-included-item {
            p {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      > div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 50px;

        .first-game {
          grid-column: 1;
        }

        .games {
          grid-column: 2;
          transition: all 0.3s ease;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      margin: 70px 185px 80px 185px;

      > div {
        .games {
          .games-included-item:nth-child(2) {
            margin-top: 50px;
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      margin: 100px 276px 90px 276px;
    }
  }
</style>
<template>
  <section class="txto-package">
    <div>
      <div class="text">
        <p>{{ c.p }}</p>
        <BaseButton :text="c.cta" />
      </div>
      <img class="one" src="../assets/package.png" alt="Jocul TXTO Junior" />
      <img class="two" src="../assets/whats-inside.png" alt="Jocul TXTO Junior pentru copii" />
      <BaseButton :text="c.cta" />
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import BaseButton from "@/components/BaseButton";

  const { getters } = useStore()
  const c = computed(() => getters.juniorPage( 'package'))
</script>

<style lang="scss">
  .txto-package {
    @media only screen and (min-width: 0) {
      margin: 70px 23px 0 23px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
          .base-button {
            display: none;
          }
        }

        .one {
          width: 274px;
          height: 415px;
          margin: 10px 0 30px 0;
        }

        .two {
          display: none;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 70px 143px 0 143px;

      > div {
        display: flex;
        flex-direction: row-reverse;

        .text {
          margin-left: 25px;

          p {
            font-size: 1.9rem;
          }

          .base-button {
            display: flex;
            font-size: 2.4rem;
          }
        }

        .one {
          display: none;
        }

        .two {
          display: flex;
          width: 229px;
          height: 282px;
        }

        .base-button {
          display: none;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      margin: 70px 185px 0 185px;
    }

    @media only screen and (min-width: 1440px) {
      max-width: 40%;
      margin: -300px 0 0 133px;

      > div {
        .text {
          p {
            font-size: 2.4rem;
          }

          .base-button {
            font-size: 3.2rem;
          }
        }
      }
    }
  }
</style>
<template>
  <div class="social-media-hangman">
    <h2>{{ c.title }}</h2>
    <div>
      <img src="../assets/icons/insta2.png" alt="" />
      <img src="../assets/icons/fb2.png" alt="" />
      <img src="../assets/icons/yt2.png" alt="" />
    </div>
  </div>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.hangmanPage( 'socialMedia'))
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .social-media-hangman {
    @media only screen and (min-width: 0) {
      margin-bottom: 30px;

      div {
        display: flex;

        img {
          width: 39px;
          height: 37px;
          margin-right: 8px;
        }
      }
    }
  }
</style>